// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.booking {
    padding: 10px 2px 0px 2px;

}
.merch {
    padding: 10px 2px 0px 2px;


}
.calendar {
    margin: auto;
    position: relative;
    padding: 4px 2px 2px 2px;
    background-color: black;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: 1em;
    }
    height: 100%;
    width: 100%;
  
  }

  .fc .fc-list-event:hover td {
    background-color: black
  }
  
  .fc .fc-list{ /* Date bar */
    border: 0px;
  }
  
  .fc .fc-button-primary{ /* Date bar */
    background-color: black;
    border-color: black
  }
  
  .fc .fc-button{ /* Date bar */
    background-color: black;
    border-color: black
  }
  
  .fc .fc-button-primary:disabled{ /* Date bar */
    background-color: black;
    border-color: black
  }
  
  .fc .fc-button-primary:hover{ /* Date bar */
    background-color: black;
    border-color: black
  }
  
  .fc table{ /* Event name text */
    color: #ffffff;
    @media screen and (max-width: 767px) {
    font-size: 0.75em;
    }
    font-size: 1.0em;
  }
  
  .fc .fc-list-day-cushion{ /* no idea */
    color: black;
    background-color: rgb(0, 0, 0);
  }
  
  .fc .fc-cell-shaded{ /* no idea */
    color: black;
    background-color: rgb(0, 0, 0);
  }

  .fc .fc-list-day{ /* Date bar */
    color: #000000;
    background-color: rgb(0, 0, 0);
  }
  
  .fc .fc-list-day-text{ /* Date bar */
    color: #ffffff;
  }
  
  .fc .fc-list-day-side-text{ /* Date bar */
    color: black;
  }